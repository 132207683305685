import React  from 'react'


import { Row , Col } from 'react-bootstrap'

//import SuperTopRightProps from './SuperComponents/SuperTopRightProps'
import SuperTopRightProps from './SuperTopRightProps'



export default function SuperCenterTopRight() {



  return (
    <div>
        <Row className='m-1 mb-0' style={{height:'18px'}}>
            <p className='body-3 font-bold'>Vacancies Statistics</p>
        </Row>
        <Row className='mt-0 g-3'>

            <Col className='m-0'>
                <SuperTopRightProps amount="325" title="New Users" isUp={false}></SuperTopRightProps>
            </Col>

            <Col className='m-0'>
                <SuperTopRightProps amount="400" title="Total Users" isUp={true}></SuperTopRightProps>
            </Col>

            <Col className='m-0'>
                <SuperTopRightProps amount="13" title="Active Users" isUp={false}></SuperTopRightProps>
            </Col>

            <Col className='m-0'>
                <SuperTopRightProps amount="25" title="Inactive Users" isUp={true}></SuperTopRightProps>
            </Col>

 

        </Row>
       
    </div>
  )
}
