import React, { useEffect , useMemo , useState} from 'react' // Hooks

import MaterialReactTable from 'material-react-table';

import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome'
import { faXmarkCircle} from '@fortawesome/free-solid-svg-icons'

import { Row , Col } from 'react-bootstrap'

import axios from 'axios'

import getGlobal from '../../../../setglobal';

export default function Invoices( {showHeader = false , closeTable } ) {

    const [data,setData] = useState([]) ;


    function getRow()
    {
        if ( showHeader )
        {

          return <>
          <Row>
            <Col className='col-2'>
            
              <FontAwesomeIcon className='pt-2 ps-2' onClick={setClose} icon={faXmarkCircle} 
              title='Close Invoices' style={{color:'blue' , height:25 , display:'inline'}}/>

            </Col>

            <Col className='col-3'>
            </Col>

            <Col>
              <h3>Invoices</h3>
            </Col>

          </Row>
          </>

        }


    }


    function setClose()
    {
      closeTable() ;

    }


    function getInvoices()
    {
        //axios.get('http://localhost:3000/api/Data/getinvoices')
        axios.get( getGlobal() + 'getinvoices')
        .then(response => {
          //console.log( JSON.stringify(response ) ) ;
    
          console.log( "invoices " + response.data.length ) ;

          console.log( "invoices " + JSON.stringify( response.data ) ) ;

          setData( response.data ) ;

    
          //industriesCb( response.data ) ;
    
        
    
        })
        .catch(e => {
          console.log( "invoices error") ;
          console.log( e ) ;
        })
        .finally(  () => { } )
      

    }

    const columns = useMemo(
      () => [
        {
          accessorKey: 'name', //access nested data with dot notation
          header: 'Name',
        },
        {
          accessorKey: 'status',
          header: 'Status',
        },
        {
          accessorKey: 'amount',
          header: 'Amount',
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
        },
        {
          accessorKey: 'sup_first_name',
          header: 'Sup First Name',
        },
        {
          accessorKey: 'sup_last_name',
          header: 'Sup Last Name',
        },
        // commission_poster
        {
          accessorKey: 'commission_supplier',
          header: 'Commision Supplier',
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
        },
        // commission_poster
  
        {
          accessorKey: 'poster_first_name',
          header: 'Poster First Name',
        },
        {
          accessorKey: 'poster_last_name',
          header: 'Poster Last Name',
        },
        {
          accessorKey: 'commission_supplier',
          header: 'Commision Poster',
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
        },
        {
          accessorKey: 'commission_gt',
          header: 'Commision H&H',
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
        },
  
      ],
      [] ,
    );
 
    useEffect(() => {

        getInvoices() ;

    } , [])



  

  return (

    <>
      {getRow()} ;
      <MaterialReactTable columns={columns} data={data} />
    </>
    
  )
}
