import  React , {useState , useRef} from 'react';

import { Container , Row , Image} from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'



//import UserMenu from '../Left2Menu/UserMenu';
import UserAddMenu from './UserAddmenu';
import CompanyMenu from './CompanyMenu';
import VacancyMenu from './VacancyMenu';
import UserEmplMenu from './UserEmplMenu';

export default function DropPlus( { stop , top , openModal , centerTable }) {


  const [theOpen,setTheOpen] = useState( false ) ;

  const divRef = useRef( 0 ) ;




   return ( 

    <Container className='p-0 m-0 overlay'   >
    <div ref={divRef} style={{height:'35vh', width:'200px'  , backgroundColor :'black' , borderRadius :'10px' }}
       className="bg-dark text-bg-success p-0 m-0">

      <Row className=' align-content-center pt-4  ps-4 mt-1' style={{ width:'100%'}}>
            {/* <UserMenu showText={true}  ></UserMenu> */}
            <UserAddMenu centerTable={centerTable} stop={stop}></UserAddMenu>
      </Row>

      <Row className=' align-content-center pt-2  ps-4 mt-1' style={{ width:'100%'}}>
            {/* <CandidatesMenu showText={true} stop={stop} top={setTop} ></CandidatesMenu> */}
            <UserEmplMenu centerTable={centerTable} stop={stop}></UserEmplMenu>
      </Row>


      <Row className=' align-content-center pt-2  ps-4 mt-1' style={{ width:'100%'}}>
            <CompanyMenu showText={true} centerTable={centerTable} stop={stop} ></CompanyMenu>
      </Row>


      <Row className=' align-content-center pt-2  ps-4 mt-1' style={{ width:'100%'}}>
            <VacancyMenu isAdd={true} showText={true} centerTable={centerTable} stop={stop} ></VacancyMenu>
      </Row>


      <Row className=' align-content-center pt-2  ps-4 mt-1' style={{ width:'100%'}}>

            <div className=' drop-item' onClick={stop}>
                  <FontAwesomeIcon  icon={faCircleXmark} title='Close' 
                  style={{color:'white' , height:25 , display:'inline'}}/>
                  <p style={{display:'inline'}}>  &nbsp; &nbsp;Close</p> 
            </div>

      </Row>


      

    </div>

    
 </Container> 


  );
}