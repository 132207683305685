import React , { useMemo , useEffect  , useState } from 'react'




import MaterialReactTable from 'material-react-table';

import axios from 'axios';

import getGlobal from '../../../../setglobal';

import { MenuItem } from '@mui/material';

import { Row , Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'



// function getFormatDate(date) {

//     var dateStr =
//         date.getFullYear() + "-" +
//         ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
//         ("00" + date.getDate()).slice(-2) + " " +
//         ("00" + date.getHours()).slice(-2) + ":" +
//         ("00" + date.getMinutes()).slice(-2) + ":" +
//         ("00" + date.getSeconds()).slice(-2);
//     console.log(dateStr);

//     return dateStr;
// }




export default function CategoriesTable( {showHeader = true , closeTable }) {

    const [data,setData] = useState([]) ;


    function getRow()
    {
        if ( showHeader )
        {

          return <>
            <Row>
              <Col className='col-2'>
                
                <FontAwesomeIcon className='pt-2 ps-2' onClick={setClose} icon={faXmarkCircle} 
                title='Close Companies' 
                style={{color:'blue' , height:25 , display:'inline'}}/>

              </Col>

              <Col className='col-3'>
              </Col>

              <Col>
                <h3>Categories</h3>
              </Col>

            </Row>
          </>

        }


    }

    function setClose()
    {
      closeTable() ;

    }

    function getCategories() // Aqui solicitamos los usuarios
    {

        var query = "" ;

      //axios.post("http://localhost:3000/api/Data/getcategories" , {
      axios.post(getGlobal() + 'getcategories' , {
          query
        } )
        .then(response => {
      
          console.log( "getCategories " + response.data.length ) ;

          var items = response.data.map( (item) => {
            //return( <RightNewaItem  key={item.id} message={item.message.substring(0,25) + "..."}></RightNewaItem> ) 

            var arr = {

                name : item.name ,
                required_points : item.required_points ,
                poster_percent : item.poster_percent ,
                supplier_parcent : item.supplier_parcent ,
                gtalents_percent : item.gtalents_percent,

            } ;


            return arr ;

            

          })

          console.log( "Data *** " + items.length) ;

          setData( items ) ; 
      
        })
        .catch(e => {
          console.log( "messages") ;
          console.log( e ) ;
        })
        .finally(  () => {
      
      
      })

    }

    useEffect(() =>{

        getCategories() ;



    } ,[])

    const columns = useMemo(
        () => [
            {
            accessorKey: 'name', //access nested data with dot notation
            header: 'Category Name',
            size: 150,
            },
            {
            accessorKey: 'required_points',
            header: 'Required Points',
            size: 150,
            },
            {
            accessorKey: 'poster_percent', //normal accessorKey
            header: 'Poster Percent',
            size: 150,
            },
            {
                accessorKey: 'supplier_parcent', //normal accessorKey
                header: 'Supplier Percent',
                size: 150,
            },
            {
            accessorKey: 'gtalents_percent',
            header: 'H&H Percent',
            size: 150,
            },
        
        
        ],
        [],
        );
    
  return (
 
    <>
        {getRow()} ;

        <MaterialReactTable
          enableRowActions 
          enableColumnResizing columns={columns} data={data}   muiTableHeadCellProps={{
          //easier way to create media queries, no useMediaQuery hook needed.
          sx: {
            fontSize: {
              xs: '10px',
              sm: '11px',
              md: '12px',
              lg: '13px',
              xl: '14px',
            },
          },
        }} muiTableBodyCellProps={{  sx: {
          fontSize: {
            xs: '10px',
            sm: '11px',
            md: '12px',
            lg: '13px',
            xl: '14px',
          },
        },}} 
        
        renderRowActionMenuItems={({ table , row }) => [
          <MenuItem key="edit" onClick={() => table.setEditingRow(row)}>
            Edit
          </MenuItem>,
          <MenuItem key="add" onClick={() => table.setEditingRow(row)}>
            Add
          </MenuItem>,
          <MenuItem key="delete" onClick={() => { data.splice(row.index, 1); //assuming simple data table
  
          setData([...data]);}}>
            Delete
          </MenuItem>,
        ]}
        
        />
    </>
  )
    
}