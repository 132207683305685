// Los botones de arriba a la derecha de los "new proyects"
import React from 'react'

import { Row , Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateLeft } from '@fortawesome/free-solid-svg-icons'

export default function NewProyectsUpRight() {
  return (
    <div style={{height:'30px', width:'100%' , maxWidth:'300px'}}>

        <Row className=' gx-1'>
            <Col className='col-6'>        
                <button className='button-3 ' style={{width:'100%', color: 'white'}}>
                    <FontAwesomeIcon icon={faArrowRotateLeft} color='white'></FontAwesomeIcon>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Undo

                </button>
            </Col>
            <Col className='col-6'>
                <div className='d-flex justify-content-center ' 
                    style={{ verticalAlign:'middle' ,  border :'1px solid rgb(211,211,211)' , borderRadius:'10px' ,height:'30px'}}>
                    <p >Most Recent</p>
                </div>
            </Col>

        </Row>


    </div>
  )
}
