import React from 'react'

import { Row , Col } from 'react-bootstrap'


import SuperCenterTopLeft from './SuperCenterTopLeft'

import SuperCenterTopRight from './SuperCenterTopRight'


export default function SuperCenterTop() {
  return (
    <div className='w100 body-4 '>

        <Row>
            <Col className='col-5'>
                <SuperCenterTopLeft></SuperCenterTopLeft>
            </Col>
            <Col className='col-7'>
                <SuperCenterTopRight></SuperCenterTopRight>
            </Col>

        </Row>
    </div>
  )
}
