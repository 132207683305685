import React , { useEffect , useState } from 'react';

//import Carousel from 'react-grid-carousel'



import VacancyCard2 from './VacancyCard2';


import { Row , Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {   faXmarkCircle } from '@fortawesome/free-solid-svg-icons'

import axios from 'axios';

import getGlobal from '../../../../setglobal';

export default function VacancyCarrousel (  { closeTable}) {

  const [cards,setcards] = useState([]) ;
  const [screensize,setScreenSize] = useState(3) ;
  const [prevSize,setPresize] = useState(0) ;
  


  function getVacacy()
  {

    //axios.get( "http://localhost:3000/api/Data/getvacancycard/?limit=all")
    axios.get( getGlobal() + "getvacancycard/?limit=all")
    .then( ( response ) =>{

      console.log( "getvacancycard length = " + JSON.stringify( response.data ) ) ;

      setcards( response.data ) ;


    })
    .catch( e => {

      console.log( e ) ;

    }

      

    ).finally(() =>{

    })


  }


  
  useEffect(() => {

    getVacacy() ;

    window.addEventListener('resize', updateDimension);
  
    
  
    return(() => {
        window.removeEventListener('resize', updateDimension);
    })

  } ,[])



  const updateDimension = () => {
    setScreenSize(getCurrentDimension())
    //getCurrentDimension() ;
}

function getCurrentDimension(){

  console.log( window.innerWidth  + "  " + screensize ) ;

  if ( window.innerWidth > 1125 )
  {
    if ( screensize!==3)
    {
      console.log( "Lo ponemos en 3")
    
     
      return 3 ;
    }
    return ;
  }

  if ( screensize!==2 )
  {
    console.log( "Lo ponemos en 2")
    
    
    return 2;
  }
  



}









    function setClose()
    {
      closeTable() ;

    }


    function getTheMap(size)
    {
      var arr = []

      if ( cards.length === 0 )
      return ;

      if ( size === undefined)
      {
        size = 3 ;
      }

      console.log( "size** = " + size ) ;

      for( var i = 0 ; i< cards.length -3  ; i+=size )
      {


          var renglon = ( <Row className=' pb-3'>
            <Col className={`${size > 2 ? "col-4" : "col-6"}`}>
              <VacancyCard2 index={i+1} name={cards[i].name.substring(0,40)} 
                company={cards[i].company} status={cards[i].status}
                username={cards[i].username}
                responsabilities={cards[i].responsabilities.substring(0,120)} screensize={screensize}></VacancyCard2>
            </Col>
            <Col className={`${size > 2 ? "col-4" : "col-6"}`}>
            <VacancyCard2 index={i+2} name={cards[i+1].name.substring(0,40)}
              company={cards[i+2].company} status={cards[i+2].status}
              username={cards[i+2].username}
              responsabilities={cards[i+2].responsabilities.substring(0,120)}></VacancyCard2>

            </Col>


          </Row> ) ;

          arr.push( renglon)


      }

      
      return arr ;

    }


    function getTheMap2(size) // Aqui debemos calcular el ancho de la pantalla
    {


      size = screensize ;

      console.log( "getTheMap2 size = " + size ) ;

      if ( size < 3 )
      {
        return getTheMap(2) ;
      }


      var arr = []

      if ( cards.length === 0 )
      return ;

      console.log( "size** = " + size ) ;

      for( var i = 0 ; i< cards.length -3  ; i+=size )
      {


        var renglon = ( <Row className=' pb-3'>
        <Col className={`${size > 2 ? "col-4" : "col-6"}`}>
          <VacancyCard2 index={i+1} name={cards[i].name.substring(0,40)} 
            company={cards[i].company} status={cards[i].status}
            username={cards[i].username}
            responsabilities={cards[i].responsabilities.substring(0,120)} screensize={screensize}></VacancyCard2>
        </Col>
        <Col className={`${size > 2 ? "col-4" : "col-6"}`}>
        <VacancyCard2 index={i+2} name={cards[i+1].name.substring(0,40)}
          company={cards[i+2].company} status={cards[i+2].status}
          username={cards[i+2].username}
          responsabilities={cards[i+2].responsabilities.substring(0,120)}></VacancyCard2>

        </Col>

        <Col className='col-4'>
        <VacancyCard2 index={i+3} name={cards[i+2].name.substring(0,40)}
            company={cards[i+3].company} status={cards[i+3].status}
            username={cards[i+3].username} 
            responsabilities={cards[i+3].responsabilities.substring(0,180)}></VacancyCard2>
        </Col>

      </Row> ) ;

        arr.push( renglon)


      }

      
      return arr ;

    }



  return (
    <>
      <div className=' m-3'>

      <Row  className=' p3-5'>
            <Col className='col-2'>
            <FontAwesomeIcon className='pt-2 ps-2' onClick={setClose} icon={faXmarkCircle} 
              title='Users' style={{color:'blue' , height:25 , display:'inline'}}/>
            </Col>

            <Col className='col-3'>
            </Col>

            <Col>
              <h3>Vacancies  </h3>
            </Col>

        </Row>    
        <div style={{overflowY:'auto' , overflowX :'hidden' ,height:'80vh'}}>
          { getTheMap2(screensize) } 
        </div> 


      </div>

    </>
  )
}



