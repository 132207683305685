


function Email( props)
{
    const onBlurHandler = (event) => { // verificar la existencia del correo electronico

        event.preventDefault() ;

        console.log( "validar email") ;

        //props.onError() ;



    }
    return (
        <div className="form-group"  style={{width: "24rem"  }}>

            <label htmlFor="email">Email</label>

            <input className="form-control" id="email" type="email" onBlur={onBlurHandler}>
            </input>

        </div>
    )

}

export default Email ;