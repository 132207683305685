import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer , BarChart , Legend , Bar } from 'recharts';

const data = [
    {
      name: 'Dic',
      closed: 500,
      accepted: 770,
    },
    {
      name: 'Jan',
      closed: 770,
      accepted: 900,
    },
    {
      name: 'Feb',
      closed: 560,
      accepted: 750,
    },
    {
      name: 'Mar',
      closed: 500,
      accepted: 750,
    },
    {
      name: 'Apr',
      closed: 700,
      accepted: 1100,
    },
    {
      name: 'May',
      closed: 720,
      accepted: 1150,
    },
  ];
  
export default class ChatTopLeft extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/stacked-area-chart-ix341';

  render() {
    return (
      <ResponsiveContainer width="100%" height="95%">
      <BarChart width={ 700} height={250} data={data} className='pb-1'>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="closed" fill="#19A5F4" />
        <Bar dataKey="accepted" fill="#7507FA" />
      </BarChart>
      </ResponsiveContainer>

      // <ResponsiveContainer width="100%" height="95%">
      //   <AreaChart
      //     width={500}
      //     height={400}
      //     data={data}
      //     margin={{
      //       top: 10,
      //       right: 30,
      //       left: 0,
      //       bottom: 0,
      //     }}
      //   >
      //     <CartesianGrid strokeDasharray="3 3" />
      //     <XAxis dataKey="name" />
      //     <YAxis />
      //     <Tooltip />
      //     <Area type="monotone" dataKey="closed" stackId="1" stroke="#8884d8" fill="#8884d8" />
      //     <Area type="monotone" dataKey="accepted" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
      //     <Area type="monotone" dataKey="undefined" stackId="1" stroke="#ffc658" fill="#ffc658" />
      //   </AreaChart>
      // </ResponsiveContainer>
    );
  }
}
