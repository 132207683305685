import React , {useState} from 'react'

import Email from '../../../../UserElements/Email'
import Password from '../../../../UserElements/Password'
import FirstlastName from '../../../../UserElements/FirstLastName'

import Phone  from '../../../../UserElements/Phone'
import Address from '../../../../UserElements/Address'
import Company from '../../../../UserElements/Company'
import ErrorMessage from '../../../../UserElements/ErrorMessage'


import { Row , Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {   faXmarkCircle } from '@fortawesome/free-solid-svg-icons'


export default function UserAdd( {closeTable}) {

    const [isErr,setIsErr] = useState( false ) ;

    function valideateErr()
    {
        setIsErr( true ) ;
    }

    function onSubmitHandler(event){

        event.preventDefault() ;

        console.log( "onSubmitHandler") ;

    }


    function setClose()
    {
      closeTable() ;
    }

  return (
    <div className="container  justify-content-center backemployer-2"  > 

        <Row>
            <Col className='col-2'>
                <h4>Add User</h4>
            </Col>
            <Col className='col-9'>
            </Col>
            <Col className='col-1'>
                <FontAwesomeIcon className='pt-2 ps-2' onClick={setClose} icon={faXmarkCircle} 
                title='Close' style={{color:'blue' , height:25 }}/>
            </Col>

        </Row>

        

        <form className="pt-2 ps-5" onSubmit={onSubmitHandler}>

            <div className="row">
                <div className='col-sm'>
                    <Email  onError={valideateErr}/>
                </div>
                <div className='col-sm'>
                    <Password maxLength={50} size={50} />
                </div>

            </div>
            <FirstlastName maxLength={60} size={60}/>
            <Phone maxLength={18} size={18} />
            <Address/>
            <hr/>
            <h4>Company</h4>
            <Company isIndustry="true"/>
            <button className="btn btn-primary mt-3 mb-3">Submit</button>
            { isErr && <ErrorMessage title={"este es el titulo"} message={"Este es el mensaje"} /> }

        </form>
    </div>
  )
}
