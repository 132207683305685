import React from 'react'


import { Row , Col, Button} from 'react-bootstrap'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserLarge , faClock } from '@fortawesome/free-solid-svg-icons'

export default function NewRegItem({name,lastname,position,eltime}) {
  return (
    <div >
        <Row className='mt-2 reg-item'>

            <Col className='col-1'>
                <FontAwesomeIcon icon={faUserLarge}></FontAwesomeIcon>
            </Col>

            <Col className='col-8'>

                <div className=' '>
                    <p className='mb-0' style={{fontFamily:'satoshi-bold', fontSize:'14px'}}>{name}{" "}{lastname}</p>
                    <p className='body-4 tx-color-gray-dark' >{position}</p>
                </div>

            </Col>

            <Col className='col-2'>

                <FontAwesomeIcon icon={faClock} style={{display:'inline-block'}}></FontAwesomeIcon>
                <p style={{display:'inline-block'}}>&nbsp;{eltime}</p>

            </Col>



        </Row>
    </div>
  )
}
