import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown , faArrowUp} from '@fortawesome/free-solid-svg-icons'

export default function SuperTopRightProps(props) {
  return (
    <div>
        <div className='body-3 box-top m-1 pt-0 w100 div-inline'  >
            <div className='body-0 mt-2 tx-color-cyan font-bold text-center'>
            <p>{props.amount}</p>
            <p className='body-4 font-regular tx-color-black'>{props.title}</p>
            </div>
            <div className=' text-center'>
              <div style={{display:'inline-block'}}>
                <p className='body-4'>{props.amount}</p>
              </div>
              {" "}

              { props.isUp ? (<div style={{display:'inline-block' , color:'green'}}>
                <FontAwesomeIcon icon={faArrowUp}></FontAwesomeIcon>
              </div> ) : ( <div style={{display:'inline-block' , color:'pink'}}>
                <FontAwesomeIcon icon={faArrowDown}></FontAwesomeIcon>
              </div>)

              }


            </div>
        </div>
    </div>
  )
}
