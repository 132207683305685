import React  from 'react'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faAddressCard, faXmark } from '@fortawesome/free-solid-svg-icons'


export default function UserEmplMenu( { stop ,  centerTable } ) {


  function setCenterTable(  )
  {
      centerTable( "UserEmpl") ;
      stop(true) ;
  }


  return (
    <div  >
        <div className=' drop-item' onClick={setCenterTable}>
          <FontAwesomeIcon  icon={faAddressCard} title='Users' 
          style={{color:'white' , height:25 , display:'inline'}}/>
          <p style={{display:'inline'}}>  &nbsp; &nbsp;Add Candidate</p> 
        </div>
        
    </div>
  )
}
