import React , { useEffect , useRef , useState }from 'react'

import Select , {  } from 'react-select';

import { Form } from 'react-bootstrap'


import axios from 'axios';


import getGlobal from '../setglobal';


// getcontracttypes

function getReplacementPeriods()
  {

    //axios.get('http://localhost:3000/api/Data/getreplacementperiods')
    axios.get( getGlobal() + 'getreplacementperiods')
    .then(response => {
      //console.log( JSON.stringify(response ) ) ;

      console.log( "replacementperiods") ;

      replacementperiodsCb( response.data ) ;

    })
    .catch(e => {
      console.log( "replacementperiods") ;
      console.log( e ) ;
    })
    .finally(  () => {


  })

}

function replacementperiodsCb(response){

  console.log( "replacementperiods.length = " + response.length ) ;

  var mySelect = document.getElementById('replacementperiods') ;

  if ( mySelect.length > 0 )
  {
    //return ;  // Ya trenia registros
  }

  for (var i = 0; i < response.length; i++) {
    var option = document.createElement("option");
    option.value = response[i].value_id.toString();
    option.text = response[i].name ;
  
    mySelect.appendChild(option);
  }

  //getLanguagesTypes() ;

}



function getAnualSalary()
  {

    //axios.get('http://localhost:3000/api/Data/getcompensations')
    axios.get( getGlobal() + 'getcompensations')
    .then(response => {
      //console.log( JSON.stringify(response ) ) ;

      console.log( "compensations") ;

      anualsalaryCb( response.data ) ;

    })
    .catch(e => {
      console.log( "compensations") ;
      console.log( e ) ;
    })
    .finally(  () => {


  })

}

function anualsalaryCb(response){

  console.log( "anualsalary.length = " + response.length ) ;

  var mySelect = document.getElementById('anualsalary') ;

  if ( mySelect.length > 0 )
  {
    //return ;  // Ya trenia registros
  }

  for (var i = 0; i < response.length; i++) {
    var option = document.createElement("option");
    option.value = response[i].id.toString();
    option.text = response[i].salary ;
  
    mySelect.appendChild(option);
  }

  getReplacementPeriods() ;

}


function getFunctionalAreas()
  {

    //axios.get('http://localhost:3000/api/Data/getfunctionalareas')
    axios.get( getGlobal() + 'getfunctionalareas')
    .then(response => {
      //console.log( JSON.stringify(response ) ) ;

      console.log( "functionaareas") ;

      functionalareasCb( response.data ) ;

    })
    .catch(e => {
      console.log( "countries") ;
      console.log( e ) ;
    })
    .finally(  () => {


  })

}

function functionalareasCb(response){

  console.log( "functionalareas.length = " + response.length ) ;

  var mySelect = document.getElementById('functionalareas') ;

  if ( mySelect.length > 0 )
  {
    //return ;  // Ya trenia registros
  }

  for (var i = 0; i < response.length; i++) {
    var option = document.createElement("option");
    option.value = response[i].value_id.toString();
    option.text = response[i].name ;
  
    mySelect.appendChild(option);
  }

  getAnualSalary() ;
  

}



function getExperienceYears()
  {

    //axios.get('http://localhost:3000/api/Data/getexperienceyears')
    axios.get( getGlobal() + 'getexperienceyears')
    .then(response => {
      //console.log( JSON.stringify(response ) ) ;

      console.log( "experienceyears") ;

      experienceyearsCb( response.data ) ;

    

    })
    .catch(e => {
      console.log( "countries") ;
      console.log( e ) ;
    })
    .finally(  () => {


  })

}

function experienceyearsCb(response){

  console.log( "experienceyears.length = " + response.length ) ;

  var mySelect = document.getElementById('experienceyears') ;

  if ( mySelect.length > 0 )
  {
    //return ;  // Ya trenia registros
  }

  for (var i = 0; i < response.length; i++) {
    var option = document.createElement("option");
    option.value = response[i].value_id.toString();
    option.text = response[i].name ;
  
    mySelect.appendChild(option);
  }

  getFunctionalAreas() ;

}



function getContractTypes()
  {

    //axios.get('http://localhost:3000/api/Data/getcontracttypes')
    axios.get( getGlobal() + 'getcontracttypes')
    .then(response => {
      //console.log( JSON.stringify(response ) ) ;

      console.log( "industries") ;

      contracttypesCb( response.data ) ;

    

    })
    .catch(e => {
      console.log( "countries") ;
      console.log( e ) ;
    })
    .finally(  () => {


  })

}

function contracttypesCb(response){

    console.log( "contracttypes.length = " + response.length ) ;

    var mySelect = document.getElementById('contracttypes') ;

    if ( mySelect.length > 0 )
    {
      //return ;  // Ya trenia registros
    }

    for (var i = 0; i < response.length; i++) {
      var option = document.createElement("option");
      option.value = response[i].value_id.toString();
      option.text = response[i].name ;
    
      mySelect.appendChild(option);
    }

    getExperienceYears() ;

  }



function getIndustries()
  {



    //axios.get('http://localhost:3000/api/Data/getindustries')
    axios.get( getGlobal()  + 'getindustries')
    .then(response => {
      //console.log( JSON.stringify(response ) ) ;

      console.log( "industries") ;

      industriesCb( response.data ) ;

    

    })
    .catch(e => {
      console.log( "countries") ;
      console.log( e ) ;
    })
    .finally(  () => {

        
  })

}


function industriesCb(response){

    console.log( "industries.length = " + response.length ) ;

    var mySelect = document.getElementById('industries') ;

    if ( mySelect.length > 0 )
    {
      return ;  // Ya trenia registros
    }

    for (var i = 0; i < response.length; i++) {
      var option = document.createElement("option");
      option.value = response[i].value_id.toString();
      option.text = response[i].name ;
    
      mySelect.appendChild(option);
    }

    getContractTypes() ;

  }


export default function PostVacancy2(props) {

  const [selectedOptions, setSelectedOptions] = useState(null);
  const [options , setOptions] = useState([]) ;
  const [check,setCheck] = useState( true ) ;


  const optionsRef = useRef() ;





    useEffect(()=>{
        getIndustries() ;
        //setShow2(false) ;
        console.log( "useEfeect") ;

        setTimeout( getLanguagesTypes(),2000)

        

        //getLanguagesTypes() ;
      } , [] ) ;


      function getLanguagesTypes()
      {
        //axios.get('http://localhost:3000/api/Data/getlanguagestypes')
        axios.get( getGlobal() + 'getlanguagestypes')
        .then(response => {
          //console.log( JSON.stringify(response ) ) ;
      
          console.log( "languagestypes = " + response.data.length ) ;
      
          languagestypesCb( response.data ) ;
      
        })
        .catch(e => {
          console.log( "languagestypes") ;
          console.log( e ) ;
        })
        .finally(  () => {
      
      
      })
      
      }
      
      function languagestypesCb( response )
      {
        console.log( "languagestypes.length = " + response.length ) ;
      
        // var mySelect = document.getElementById('functionalareas') ;
      
        // if ( mySelect.length > 0 )
        // {
        //   return ;  // Ya trenia registros
        // }

        var arr = response.map( function(item )  {

          var obj = {} ;
  
          obj.value = item.value_id ;
          obj.label = item.name ;
  
          return obj ;
      })
  
      setOptions( arr ) ;
      
 
      }
      

      function handlePercentage(e)
      {
          //e.preventDefault() ;

          //return ; 

          console.log( "handlePercentage") ;

          if ( e.target.name === "percentage")
          {
            console.log( "Se tecleo percentage")
            if ( !check)
              setCheck( true ) ;
          }
          else{
            console.log( "Se tecleo fijo")
            //e.target.checked = false ;
            if ( check )
              setCheck( false )
          }

          console.log( "check = " + check ) ;

      }

      function handleOnChange(value) {

        


        console.log( "handleOnChange = " + JSON.stringify( value ) ) ;
    
        setSelectedOptions( value ) ;
    
      }

      function onInputChangeHandler(event){
        //event.preventDefault() ;
    
        console.log( "selected(1) = " + JSON.stringify(selectedOptions )) ;
      }

    const handlePrev = (e) => {

        e.preventDefault() ;

        props.gotoPage1() ; // Solicitamos que se vaya a la pagina 1 

    }

  return (
    <div className='container justify-content-center backemployer-2' style={{height: "100vh"} }>

        <Form className='pt-5' >

            <div className='row'>

                <div className='col-sm'>
                <div className='form-group'>

                    <label htmlFor="industries">Industries</label>

                    <select className="form-control w-40" id="industries" 
                    type="select" maxLength={50}   >

                    </select>

                </div>
                </div>
                <div className='col-sm align-content-right'>
                <div className='form-group'>

                    <label htmlFor="seartchtype">Search Type</label>

                    <select className="form-control w-40" onChange={console.log("hola")} id="seartchtype" 
                    type="select" maxLength={50}   >
                        <option value="1">Search</option>
                        <option value="2">Contency</option>
                        <option value="3">Retained</option>
                    </select>

                </div>
                </div>
            </div>

            <div className='row'>
              <div className='col-sm align-content-right'>
                <div className='form-group'>

                    <label htmlFor="contracttypes">Contract Type</label>

                    <select className="form-control w-40" id="contracttypes" 
                    type="select" maxLength={50}   >
                    </select>

                </div>
              </div>


              <div className='col-sm align-content-right'>
                <div className='form-group'>

                    <label htmlFor="experienceyears">Experience Years</label>

                    <select className="form-control w-40" id="experienceyears" 
                    type="select" maxLength={50}   >

                    </select>

                </div>
              </div>
              

            </div>


            <div className='row'>
              <div className='col-sm-12 align-content-right'>
                <div className='form-group'>

                    <label htmlFor="functionslaread">Funtional Sreas</label>

                    <select className="form-control w-40" id="functionalareas" 
                    type="select" maxLength={50}   >

                    </select>

                </div>
              </div>



            </div>

            <div className='row'>

              <div className='col-sm-4 align-content-right area mt-1'>
              <label htmlFor="languages" style={{color:'white'}}>Languages</label>
              <Select 
                defaultValue={selectedOptions}
                onChange={handleOnChange}
                onInputChange={onInputChangeHandler}
                options={options}
                isMulti={true}
                closeMenuOnSelect={false}
                ref={optionsRef}
                id="languages"
              />
              </div>

            </div>

            <div className='row'>

              <div className='col-sm-6 align-content-right'>
                <div className='form-group'>

                    <label htmlFor="anualsalary">Anual Salary</label>

                    <select className="form-control w-40" id="anualsalary" 
                    type="select" maxLength={50}   >

                    </select>

                </div>
              </div>


              <div className='col-sm-6 align-content-right'>
                <div className='form-group'>

                  <div className='row'>

                  <div className='col-sm'>

                    <label htmlFor="anualsalary">Total Search Fee</label>

                    <div className="form-check">
                      <input className="form-check-input" type="radio" 
                      name="percentage" id="percent" onClick={handlePercentage}  checked={check}/>
                      <label className="form-check-label" htmlFor="flexRadioDefault1" >
                      Percentage
                      </label>
                    </div>
                    <div className="form-check">
                      <input 
                      className="form-check-input" type="radio" 
                      name="fixed" id="fixed"  checked={!check} onClick={handlePercentage}/>
                      <label className="form-check-label" htmlFor="flexRadioDefault2">
                      $ Fixed Fee
                      </label>
                    </div>
                  </div>

                  <div className='col-sm'>
                    <div className='row'>
                        {!check&&(<label >$</label>)}
                        {check&&(<label>%</label>)}
                        <div class="form-outline" >
                          
                          <input type="number" id="percentage" className="form-control" />
                          
                          
                        </div>
                        
                      
                    </div>
                    
                  </div> 

                  </div>

                </div>
              </div>

            </div>

            <div className='row'>


              <div className='col-sm-6 align-content-right'>
                <div className='form-group'>

                    <label htmlFor="replacementperiods">Replacement Priods</label>

                    <select className="form-control w-40" id="replacementperiods" 
                    type="select" maxLength={50}   >

                    </select>

                </div>
              </div>

              <div className='col-sm-6 align-content-right'>
                <div className="form-group"  style={{width: "100%"  }} >
                    <label htmlFor="warrantydetail">Warranty Detail</label>
                    <input type="text" className="form-control" id="warrantydetail"  
                    placeholder="Warranty Detail"  
                    />
                    
                  </div>


              </div>



            </div>

            <div className='row'>

              <div className="form-group" style={{width: "100%"  }} >
                <label htmlFor="generalconditions">General Conditions</label>
                <textarea  className="form-control form-control-sm" 
                placeholder="General Conditions" id="generalconditions"  rows="2" />
              </div>

            </div>

            <div className='row mt-3 ps-3'>

            <div class="form-check ">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  />
              <label class="form-check-label small" for="flexCheckChecked">
                I accept terms of service
              </label>
              </div>

            </div>


            <button type="button" className="btn btn-primary mt-3" onClick={handlePrev}>Prev</button>
        </Form>
        

    </div>
  )
}
