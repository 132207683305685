import React from 'react'


import { Row , Col, Button } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faPencilSquare , faClock , faCircleUser } from '@fortawesome/free-solid-svg-icons'

export default function VacancyCard2( {name,company,status,username  , responsabilities} ) {
  return (
    <div className=' box-4 ' style={{height:'220px'}}>

        <div style={{height:'10%'}}>
            <Row >
                <Col className=' col-11'>
                    <div style={{wordWrap:'break-word'}}>
                        <p style={{fontWeight:'bold'}}>{name}</p>
                    </div>
                    
                </Col>
                <Col className=' col-1'>
                    <FontAwesomeIcon  icon={faPencilSquare} title='Vacancy' style={{color:' grey' , height:12 }}/>
                </Col>
            </Row>        
        </div>

        <div style={{height:'5%'}}>

            <Row style={{height:'100%'}}>
                <Col className=' col-12'>
                    <p style={{fontSize:'10px'}}>{company}</p>
                </Col>
            </Row>

        </div>

        <div className='pt-2' style={{height:'10%'}}>
            <Row className=' pb-0'  style={{height:'20px'}}>
                <Col className='col-6'>
                    <div class=" ps-0 text-center vertical-center" style={{ fontSize : '16px' , height:'20px' , 
                    backgroundColor:'#F500FF', borderColor:'white' , borderRadius : '10px' }}>

                        <p className=' p-0 m-0' >{status}</p>

                    </div>            
                </Col>
                
            </Row>
          
        </div>
        <div className='pt-2' style={{height:'10%'}}>
            <Row className=' pt-0  ' style={{height:'22px'}}>
                <Col className='col-1 ' style={{height:'22px'}}>
                    <FontAwesomeIcon  icon={faClock} title='Vacancy' style={{color:' grey' , height:'10px' }}/>
                </Col>
                <Col className='col-5 pt-2 ' style={{height:'22px'}}>
                    <p style={{fontSize :'10px' , height :'10px'}}>10 min ago</p>
                </Col>

            </Row>
        
        </div>

        <div>

            <Row style={{height:'22px'}}>
                <Col className='col-1' style={{height:'22px'}}>
                    <FontAwesomeIcon  icon={faCircleUser} title='Vacancy' style={{color:' grey' , height:'10px' }}/>

                </Col>

                <Col className='col-8 pt-2' style={{height:'22px'}}>
                    <p style={{fontSize :'10px' , height :'10px'}}>Posted by {username}</p>
                </Col>
            </Row>


        </div>


        <div className='pt-2' style={{height:'12%'}}>
            <Row>

                <Col className='col-8'>
                    <p style={{fontSize :'18px' , height :'18px'}}>Job Description</p>
                </Col>

            </Row>

        </div>


        <div className='pt-3' style={{height:'30%' , wordWrap:'break-word'}}>

            <p style={{fontSize :'12px' , height :'12px'}}>

                {company} - {responsabilities}

            </p>

        </div>


    </div>
  )
}
