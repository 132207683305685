import React from 'react'

import { Row , Col } from 'react-bootstrap'

//import SuperGoodJob from './SuperComponents/SuperGoodJob'
import SuperGoodJob from './SuperGoodJob'
//import SuperGoogStats from './SuperComponents/SuperGoogStats'
import SuperGoogStats from './SuperGoogStats'

import SuperTopRightProps from './SuperTopRightProps'

export default function SuperCenterTopLeft() {
  return (
    <div>

        <Row className='m-1 mb-0' style={{height:'18px'}}>
            <p className='body-3 font-bold'>Performance</p>
        </Row>

        <Row>


            <Col className='m-0'>
                <SuperTopRightProps amount="3.25" title="Gain" isUp={false}></SuperTopRightProps>
            </Col>

            <Col className='m-0'>
                <SuperTopRightProps amount="23" title="Accounts Payable" isUp={true}></SuperTopRightProps>
            </Col>

            <Col className='m-0'>
                <SuperTopRightProps amount="35" title="New Companies" isUp={false}></SuperTopRightProps>
            </Col>

            <Col className='col-1 m-0'>
                <div className="vl" ></div>
            </Col>

        </Row>





    </div>
  )
}
