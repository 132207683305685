
import { useEffect  , useState } from "react";

import axios from "axios";

import getGlobal from "../setglobal";

function citiesCb( response )
{
   var mySelect2 = document.getElementById('city') ;

   buildOptions( response , 'city2' , 'id' , 'name') ;

}

function getCities( id )
{
   console.log( "Llamando a cities = " + id ) ;

   //axios.get("http://localhost:3000/api/Data/getcities?id=" + id )
   axios.get( getGlobal() + "getcities?id=" + id )
   .then(response => {
     //console.log( JSON.stringify(response ) ) ;

     console.log( "cities") ;

     citiesCb( response.data ) ;

   })
   .catch(e => {
     console.log( "countries") ;
     console.log( e ) ;
   })
   .finally(  () => {


 })
}



function statesCb(response)
 {
     var mySelect2 = document.getElementById('state') ;
     buildOptions( response , 'state2' , 'id' , 'name') ;
 }




function getStates(id)
  {
    //axios.get("http://localhost:3000/api/Data/getstates?id=" + id )
    axios.get( getGlobal() + "getstates?id=" + id )
    .then(response => {
      //console.log( JSON.stringify(response ) ) ;

      console.log( "getStates " ) ;
      statesCb( response.data ) ;

    })
    .catch(e => {
      console.log( "states error " + e ) ;
      //console.log( e ) ;
    })
    .finally(  () => {


  })

}


function countriesCb(response){
    buildOptions( response , 'country2' , 'id' , 'name') ;
    //getCategories() ;
    getStates() ;
  }
  
  
  function getCountries()
  {
  
  
    //axios.get('http://localhost:3000/api/Data/getcountries')
    axios.get( getGlobal() + 'getcountries')
    .then(response => {
      console.log( "countries Jalando " + response.data.length ) ;
      countriesCb( response.data ) ;
    })
    .catch(e => {
      console.log( "countries error jalando " + e ) ;
    })
    .finally(  () => {
  
  
  })
  
  }

  function buildOptions( response , element , value , name)
  {



    var mySelect = document.getElementById(element) ;

    if ( mySelect.options === undefined)
    {
        console.log( "undefines") ;
    }
    else{
        console.log( "No es undefined") ;
    }

    if ( mySelect.options.length > 0 )
    {
      removeOptions( mySelect ) ;      
    }


    var option = document.createElement("option");
    option.value = "0";
    option.text = "Select option" ;
    mySelect.appendChild(option);

    

    for (var i = 0; i < response.length; i++) {
      var option = document.createElement("option");
      // option.value = response[i].value_id.toString();
      // option.text = response[i].name ;

      option.value = response[i][value].toString();
      option.text = response[i][name] ;
    
      mySelect.appendChild(option);
    }

  }


  function removeOptions(selectElement) {

    if ( selectElement.options.length < 1 )
    {
      return ; 
    }
  
    var i, L = selectElement.options.length - 1;
    for(i = L; i >= 0; i--) {
       selectElement.remove(i);
    }
  }


function Address2 ( ) {

    const [stateHidden,setStateHidden] = useState(true) ;
    const [cityHidden,setCityHidden] = useState(true) ;



    useEffect(()=>{

        getCountries() ;
    
        
    
      } , [] ) ;


      const handleState = (e) => {

        console.log( "handleState = " + e.target.value ) ; 
    
        if ( e.target.value > 0 )
        {
    
      
    
          setCityHidden ( false );
    
          getCities( e.target.value ) ;
        }
        else{
          //setStateHidden ( true );
          setCityHidden( true ) ;
    
          removeOptions( 'city')
    
        }
    
    
      }
      


    const handleCountry = ( e ) => {

        console.log( "handleCountry = " + e.target.value ) ; 
    
        if ( e.target.value > 0 )
        {
          setStateHidden ( false );
    
          getStates( e.target.value ) ;
        }
        else{
          setStateHidden ( true );
    
    
        }
    
          setCityHidden( true ) ;
    
          var myCity = document.getElementById( 'city2') ;
    
          removeOptions( myCity)
    
      
    }
    
    

    return (

        <div >

            <div className="row">

                <div className="col">
                    <div className="form-group"  style={{width: "24rem"  }} >
                        <label htmlFor="address2">Address</label>
                        <input className="form-control" id="address2" type="text" maxLength={120} size= {100}/>
                    </div>
                </div>


                <div className="col">
                    <div className="form-group"  style={{width: "24rem"  }} >
                        <label htmlFor="complement2">Complement</label>
                        <input className="form-control" id="complement2" type="text" maxLength={120} size= {100}>
                        </input>
                    </div>
                </div>


            </div>


            <div className="row">

                <div className='col-sm'>
                    <div className="form-group"  style={{width: "24rem"  }} >
                        <label htmlFor="country2">Country***</label>

                        <select className="form-control h6" id="country2"  
                         onChange={handleCountry} style={{fontSize:'14px'}}>
                        </select>
                    </div>
                </div>


                <div className="col-sm" hidden={stateHidden}>
                    <div className="form-group"  style={{width: "24rem"  }} >
                        <label htmlFor="state2">State***</label>

                        <select className="form-control h6" id="state2"  
                            onChange={handleState} style={{fontSize:'14px'}}>
                        </select>                    
                    </div>
                </div>

                </div>

                <div className="row">
                <div className="col-sm-3" hidden={cityHidden} >
                    <div className="form-group"  style={{width: "24rem"  }} >
                        <label htmlFor="city2">City***</label>

                        <select className="form-control h6" id="city2"  
                             style={{fontSize:'14px'}}>
                        </select>                    

                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col">
                    <div className="form-group"  style={{width: "24rem"  }} >
                        <label htmlFor="zipcode2">Zip Code</label>
                        <input className="form-control" id="zipcode2" type="text" maxLength={12}/>
                    </div>
                </div>
            </div>

        </div>

    )

}

export default Address2 ;