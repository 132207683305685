import React, { useState , useEffect } from 'react';
import Select , {  } from 'react-select';

import axios from 'axios' ;

import getGlobal from '../setglobal';


export default function IndustriesAreas() {
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [options , setOptions] = useState([]) ;



  useEffect(()=>{

    //setTimeout( getindustries , 200 );

    getindustries() ;

  } , []) ;

  function getindustries() 
  {
    // https://jsonplaceholder.typicode.com/todos/1
    //axios.get('http://localhost:3000/api/Data/getindustries') // api/Data/getindustries
    axios.get( getGlobal() + 'getindustries') 
    .then(response => {
      //console.log( JSON.stringify(response ) ) ;

      //console.log( "industries " + JSON.stringify( response )) ;

      myFunction( response.data ) ;

    })
    .catch(e => {
      console.log( "industries") ;
      console.log( e ) ;
    })
    .finally(  () => {

      console.log( "Hemos terminado en indistries") ;
    }) ;


  }



  function myFunction(response){

    var arr = response.map( function(item )  {

        var obj = {} ;

        obj.value = item.value_id ;
        obj.label = item.name ;

        return obj ;
    })

    setOptions( arr ) ;

  }
  function onInputChangeHandler(event){
    //event.preventDefault() ;

    console.log( "selected(1) = " + JSON.stringify(selectedOptions )) ;
  }

  function handleOnChange(value) {


    console.log( "handleOnChange = " + JSON.stringify( value ) ) ;

    setSelectedOptions( value ) ;

  }

  return (
    <div className="area" > 
      <Select 
        defaultValue={selectedOptions}
        onChange={handleOnChange}
        onInputChange={onInputChangeHandler}
        options={options}
        isMulti={true}
        closeMenuOnSelect={false}
      />
    </div>
  );
}
