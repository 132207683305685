import React from 'react'

import { Row , Col } from 'react-bootstrap'

import SuperCenterTop from './SuperCenterTop'


//import RightNews from '../SuperComponents/RightNews'
import RightNews from './RightNews'

//import SuperCenterMiddle from './SuperCenterMiddle'
import SuperCenterMiddle from './SuperCenterMiddle'

import ChartAccount from './ChartAccount'

import NewProyects from './NewProyects'
import ProyectStatus from './ProyectStatus'


import NewRegisters from './NewRegisters'



export default function SuperCenter() {
  return (
    <>

      <div className='mt-0'  style={{width:'100%' , overflowY:'scroll'}}>

      
        <div className=' dash-vertical' style={{display:'inline-block' , height:'75vh'  , width:'75%'}}>
          <div className=' box-2 m-2' >
            <SuperCenterTop></SuperCenterTop>
          </div>

          <div className='mt-2 pt-0 ps-1 pe-3' style={{height:340 , backgroundColor:'bg-right'}}>

            <SuperCenterMiddle></SuperCenterMiddle>

          </div>    

          <div className='box-2 ms-2 pe-4 me-2 mt-1 ' style={{height:300 , backgroundColor:'white' , borderRadius:'10px'}}>

            
            <NewProyects></NewProyects>
          </div>    

        </div>

        <div  className=' dash-vertical pt-2'
          style={{display:'inline-block' , height:'100vh' , width:'24%'}}>
          <div className='  p-0 mb-5' style={{ height:'100%'}} >
            <div className=' box-2 pt-3 pe-1 mb-2' style={{width:'100%' }}>
              <RightNews></RightNews>
            </div>

            {/* <TipBannerCarousel className=" mb-2" style={{borderRadius:'10px'}}></TipBannerCarousel> */}

            
            <div className=' box-2 pt-3 ps-1 pe-0 mt-2' style={{border:2 , height:300 , width:'100%'}} >
              {/* <ChartAccount  ></ChartAccount> */}
              <ProyectStatus></ProyectStatus>
            </div>
            
            
          </div>
          
        </div>
      </div>
    </>
  )
}
