import React , { useState , useRef , useEffect } from 'react'

import { Container , Row , Col ,Image} from 'react-bootstrap'


import DropPlus from './Plus/DropPlus'


//import CandidatesModal from './CandidatesModal';
import CandidatesModal from './Plus/CandidatesModal'



export default function TopRight({centerTable}) {

    const refOne = useRef(0 )
    const refTwo = useRef(0)


    const [drop,setDrop] = useState(false) ;


    const [isListen,setIsListen] = useState( true )
    const [theOpen,setTheOpen] = useState( false ) ;

    useEffect( () => {
        console.log( "addEventListener")

        //setIsListen( true ) 

        //document.addEventListener( "click" , handleOutside ,  false ) ;

        //refTwo.addEventListener( "click" , handleOutside ,  false )

        document.getElementById( 'myImage').addEventListener( "click" , handleOutside ,  false  )

        setTimeout( () => console.log( "isListen = " + isListen ) , 5000 ) ;
    } ,[ ])


    // document.removeEventListener("mousedown", handleMouseDown, true); // Succeeds

    function openModal()
    {
        setTheOpen( true ) ;
    }

    function stopListen(val)
    {

        console.log( "Llegando stopListen " + val ) ;

        //document.removeEventListener("click", handleOutside);

        if ( val )
        {
            setIsListen( false ) ;

            setTheOpen( true ) ;

 
            setTimeout( () => console.log( "stopListen = " + isListen ) , 5000 ) ;

        }else{

            console.log( "Lo regresamos a true ") ;
            setIsListen( true ) ;

        }
        


        console.log( "Despues de stopListen")
    }





    const handleOutside = (e) => {

            e.preventDefault() ;

            console.log( "handleOutside drop = " + drop )

            if ( ( refTwo.current.contains( e.target ) ) /* && !drop */)
            {
                console.log( "Lo activamos " + drop ) ;
                setIsListen( false ) ;
                setTheOpen( false ) ;
                setDrop( true ) ;
 
                return ;
            }

            if ( ( !refOne.current.contains( e.target  ) )  )
            {
                console.log( "Estamos fuera") ;
                setDrop( false ) ;
                return ; 
            }

    }



    function closeModal( ){

        console.log( "closeModal = " + drop ) ;

        setTheOpen( false ) ;
        setDrop( false ) ;
    }






  return (

    <Container  >

        <Row className=' gx-1'>

            <Col className='col-sm-3'></Col>

            <Col className='col-sm-7 '>
            
            <div className="input-group w-100" >

                <div className="form-outline w-100 input-sm  pe-2 " style={{display:'inline-block'}}>
                    <input type="search" id="search001" 
                        className="form-control top-search button-3 " 
                        size='sm' placeholder='Seach Oportunity' style={{color:'black' , display:'inline-block'}}/>

 
                    
                </div>

            </div>                

            </Col>
            <Col className='col-1'>            
                <div  >
                    <Image ref={refTwo} id='myImage' src='/action-icon.svg' size='30px' alt=''
                      style={{width:'30px'}}/*onClick={theClick}*/ ></Image>

                      <div ref={refOne}>
                        { drop && !theOpen && ( <DropPlus stop={stopListen} 
                           centerTable={centerTable} ></DropPlus> )}
                      </div>
                  
                </div>
            </Col>

            <Col className='col-1'>            
                <div >
                    <Image src='/user-perfil-icono.svg' size='30px' alt='' style={{width:'30px'}}></Image>
                </div>
            </Col>


        </Row>


        {/* <CandidatesModal  theOpen={theOpen} closeModal={closeModal} ></CandidatesModal> */}

        
    </Container>
    
  )
}
