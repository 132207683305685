// Aqui tenemos el telefono principal y el secundario

function Phone(props) {

    return (

    <>
    <div className="row">

        <div className='col-sm'>
            <div className="form-group"  style={{width: "24rem"  }} >
                <label htmlFor="phone">Phone</label>
                <input className="form-control" id="phone" type="text" maxLength={props.maxLength} size= {props.size}/>
            </div>
        </div>


        <div className='col-sm'>
            <div className="form-group"  style={{width: "24rem"  }} >
                <label htmlFor="secondaryphone">Secondary Phome</label>
                <input className="form-control" id="secondaryphone" type="text" maxLength={props.maxLength} size= {props.size}/>
            </div>
        </div>
    </div>


    </>

    )

}

export default Phone ;