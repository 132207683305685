

function Address ( ) {

    return (

        <div >
            <div className="row">

                <div className="col-8">

                    <div className="form-group input-group-sm"  style={{width: "24rem" , display:'inline' }} >
                        <label htmlFor="address">Address</label>
                        <input  className="form-control" id="address" type="text" maxLength={120} size= {100}/>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-8">
                    <div className="form-group input-group-sm"  style={{width: "24rem" , display:'inline' }} >
                        <label htmlFor="complement">Complement</label>
                        <input className="form-control" id="complement" type="text" maxLength={120} size= {100}/>
                    </div>
                </div>
            </div>


            <div className="row">

                <div className='col-sm'>
                    <div className="form-group"  style={{width: "24rem"  }} >
                        <label htmlFor="country">Country</label>
                        <input className="form-control " id="country" type="text" />
                    </div>
                </div>


                <div className="col-sm">
                    <div className="form-group"  style={{width: "24rem"  }} >
                        <label htmlFor="state">State</label>
                        <input className="form-control" id="state" type="text"/>
                    </div>
                </div>

                <div className='col-sm-1'>
                    
                </div>
    
                        
 
                    

            </div>

            <div className="row">
                <div className="col-sm-3">
                    <div className="form-group"  style={{width: "24rem"  }} >
                        <label htmlFor="city">City</label>
                        <input className="form-control" id="city" type="text"  />
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col">
                    <div className="form-group"  style={{width: "24rem"  }} >
                        <label htmlFor="zipcode">Zip Code</label>
                        <input className="form-control" id="zipcode" type="text" maxLength={12}/>
                    </div>
                </div>
            </div>





        </div>

    )

}

export default Address ;