import React , { useEffect , useState } from 'react'



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faUserGroup  } from '@fortawesome/free-solid-svg-icons'





export default function CompaniesMenu( {  centerTable}) {

  const [theOpen,setTheOpen] = useState( false ) ;


  function setCenterTable(  )
  {
      centerTable( "CompaniesTable") ;
  }



  return (
    <div  >

      
        <div  onClick={setCenterTable}>
          <FontAwesomeIcon  icon={faUserGroup} title='Companies' style={{color:'white' , height:25}}/>
        </div>
        

   
    </div>
  )
}
