import React from 'react'

import { Row , Col, Button} from 'react-bootstrap'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import NewRegItem from './NewRegItem'

export default function NewRegisters() {
  return (
    <div className='pt-2 ' style={{width:'98%' , height:'310px'}}>

        <div>
            <Row>

                <Col className='col-4'>
                    <p className='font-bold' style={{height:'25px' , fontFamily:'satoshi-bold'}}>New Registers</p>
                </Col>

                <Col className='col-1' style={{color:'white'}}>
                    <FontAwesomeIcon color='purple' icon={faCircle}>10</FontAwesomeIcon>
                </Col>

                <Col className='col-4' >
                    <button className='button-3' style={{color:'white' , width:'100%'}} >View All</button>
                </Col>

                <Col className='col-3'  >
                    <button  style={{ borderColor :'rgb(211,211,211)' , border :'2' , borderRadius :'5px' , width:'90%'}}>Most Recent</button>
                </Col>


            </Row>
        </div>
            
        <div className='mt-2' style={{overflowY:'auto', height:'230px' , overflowX :'hidden'}}>
            <Row>
                <NewRegItem name={"Diana"} lastname={"Hamilton"} position={"Sales Director"} eltime={"10 min"}></NewRegItem>
            </Row>

                

            <Row>
                <NewRegItem name={"Waseem"} lastname={"Ahmed"} position={"Software Engeneer"} eltime={"2 hours"}></NewRegItem>
            </Row>

                

            <Row>
                <NewRegItem name={"Robert"} lastname={"Oaks"} position={"Tech Director"} eltime={"1 Day"}></NewRegItem>
            </Row>

            <Row>
                <NewRegItem name={"Daniele"} lastname={"Rosa"} position={"Assintant"} eltime={"1 Day"}></NewRegItem>
            </Row>

            <Row>
                <NewRegItem name={"Ana"} lastname={"Velazco"} position={"Programmer"} eltime={"1 Day"}></NewRegItem>
            </Row>

            <Row>
                <NewRegItem name={"Kyaw"} lastname={"Min"} position={"Art Designer"} eltime={"2 Days"}></NewRegItem>
            </Row>


        </div>

    </div>
  )
}
