

function Password( props) {

    return (
        <div className="form-group"  style={{width: "24rem"  }}>

            <label htmlFor="password">Password</label>

            <input className="form-control" id="password" type="password" maxLength={props.maxLength} size= {props.size}>
            </input>

        </div>

    )


}

export default Password ;