

function FirstlastName(props)
{

    return (
        <div className="row">

            <div className='col-sm'>
                <div className="form-group"  style={{width: "24rem"  }} >
                    <label htmlFor="firstname">First Name</label>
                    <input className="form-control" id="firstname" type="text" maxLength={props.maxLength} size= {props.size}>
                    </input>

                </div>
            </div>


            <div className='col-sm'>
                <div className="form-group"  style={{width: "24rem"  }} >
                    <label htmlFor="lastname">Last Name</label>
                    <input className="form-control" id="lastname" type="text" maxLength={props.maxLength} size= {props.size}>
                    </input>
                </div>
            </div>
        </div>
    )

}

export default FirstlastName ;