import logo from './logo.svg';
import './App.css';

import DashAdmin from './Dashboard/AdminPanel/DashAdmin/DashAdmin';

import { BrowserRouter, Route, Routes, Link } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      {/* <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </div> */}
      <Routes>
        <Route path="/" Link="/Dashboard/AdminPanel/DashAdmin/DashAdmin" element={<DashAdmin />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
