import React from 'react'
import { Row , Col } from 'react-bootstrap'

//import ChatTopLeft from './SuperComponents/ChartTopLeft'
import ChatTopLeft from './ChartTopLeft'
//import ProyectStatus from './SuperComponents/ProyectStatus
import ProyectStatus from './ProyectStatus'

import NewRegisters from './NewRegisters'

export default function SuperCenterMiddle() {
  return (
    <div  style={{width:'100%'}} >

    <Row className=' ms-1 g-3' style={{height:'320px'}}>   
        <Col className='col-6 me-0 ps-0 ' style={{  borderRadius:'10px'}}>
 
            <div className='mb-0 box-2 m-0' style={{height:'320px'}}>
                <p className='ps-4' style={{fontFamily: 'Satoshi-Bold'}}>Account Statistics</p>
                <ChatTopLeft className='mt-0'></ChatTopLeft>
            </div>
            
        </Col>
        <Col className=' pe-0 ' style={{backgroundColor:'white' , border : '1px solid #EBEBEB',  borderRadius:'10px', width:'31%'}}>
           
            {/* <ProyectStatus></ProyectStatus> */}
            <div className='pt-2'>
              <NewRegisters ></NewRegisters>
            </div>
            
        </Col>

    </Row>
    </div>
  )
}
