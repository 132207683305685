import React from 'react'

import { Container , Row , Image} from 'react-bootstrap'

import { MdOutlinePeople } from "react-icons/md";

import { IoPeople } from "react-icons/io5";



import { AiOutlineFolderOpen , AiFillBell, AiFillFolderOpen } from "react-icons/ai";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen , faCheckToSlot , faMessage , faBell , faCircleUser , faCircleDollarToSlot , faGear } from '@fortawesome/free-solid-svg-icons'


import UserMenu from './Left2Menu/UserMenu';
import CompaniesMenu from './Left2Menu/CompaniesMenu';
import InvoicesMenu from '../DashAdmin/Left2Menu/InvoicesMenu'
import MessagesMenu from '../DashAdmin/Left2Menu/MessagesMenu';

import CategoriesMenu from '../DashAdmin/Left2Menu/CategoriesMenu'

import VacancyMenu from './Plus/VacancyMenu';


export default function Left2( {centerTable}) {
  return (
    <Container className='p-0 m-0'  >
        <div style={{height:'100vh', width:'75px' ,  backgroundColor :'black' }}
           className="bg-dark text-bg-success p-0 m-0">
          <Row className=' align-content-center py-2 m-0'>
            <Image  src='/logo-hunter-hire-icono.svg' alt='' width={'65vh'}></Image>
          </Row>

          <Row className=' align-content-center  ps-4 mt-4' style={{top:200 , width:'100%'}}>
              <UserMenu centerTable={centerTable} ></UserMenu>
            
            {/* <FontAwesomeIcon icon={faCircleUser} title='Users' style={{color:'white' , height:25}}/> */}
          </Row>

          <Row className=' align-content-center ps-4 py-2 ' style={{ width:'100%'}}>
            {/* <FontAwesomeIcon icon={faFolderOpen} title='Vacancies' style={{color:'white' , height:25}}/> */}
            <VacancyMenu centerTable={centerTable}></VacancyMenu>
          </Row>

          <Row className=' align-content-center py-2' style={{ width:'100%'}}>
            <FontAwesomeIcon icon={faBell} title='Notifications' style={{color:'white' , height:25}}/>
          </Row>

          <Row className=' align-content-center ps-4 py-2' style={{ width:'100%'}}>
            {/* <FontAwesomeIcon icon={faUserGroup} title='Companies' style={{color:'white' , height:25}}/> */}
            <CompaniesMenu centerTable={centerTable}></CompaniesMenu>
          </Row>

          
          <Row className=' align-content-center ps-4 py-2' style={{ width:'100%'}}>
             {/* <FontAwesomeIcon icon={faCircleDollarToSlot} title='Invoices' style={{color:'white' , height:25}}/> */}
             <InvoicesMenu centerTable={centerTable} ></InvoicesMenu>
          </Row>


          <Row className=' align-content-center ps-4 py-2' style={{ width:'100%'}}>
            {/* <FontAwesomeIcon icon={faMessage} title='Messages' style={{color:'white' , height:25}}/> */}
            <MessagesMenu centerTable={centerTable} ></MessagesMenu>
          </Row> 

          <Row className=' align-content-center ps-4 py-2' style={{ width:'100%'}}>
            {/* <FontAwesomeIcon icon={faMessage} title='Messages' style={{color:'white' , height:25}}/> */}
            <CategoriesMenu centerTable={centerTable} ></CategoriesMenu>
          </Row> 


          <Row className=' align-content-center py-2' style={{ width:'100%'}}>
            <FontAwesomeIcon icon={faCheckToSlot} title='Logs' style={{color:'white' , height:25}}/>
          </Row>


          <Row className=' align-content-center py-2' style={{ width:'100%'}}>
            <FontAwesomeIcon icon={faGear} title='Configuration' style={{color:'white' , height:25}}/>
          </Row>
        
        </div>
    </Container>
  )
}
