import React , { useMemo , useEffect  , useState } from 'react'




import MaterialReactTable from 'material-react-table';

//import { Box, IconButton } from '@mui/material';

import { MenuItem } from '@mui/material';


import { Row , Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {   faXmarkCircle } from '@fortawesome/free-solid-svg-icons'



import axios from 'axios';

import getGlobal from '../../../../setglobal';


// function getFormatDate(date) {

//     var dateStr =
//         date.getFullYear() + "-" +
//         ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
//         ("00" + date.getDate()).slice(-2) + " " +
//         ("00" + date.getHours()).slice(-2) + ":" +
//         ("00" + date.getMinutes()).slice(-2) + ":" +
//         ("00" + date.getSeconds()).slice(-2);
//     console.log(dateStr);

//     return dateStr;
// }




export default function UserTable( {showHeader = false , closeTable }) {

    const [data,setData] = useState([]) ;


    function setClose()
    {
      closeTable() ;

    }


    function getRow( )
    {
      if ( showHeader )
      {
        return <>
          <Row>
            <Col className='col-2'>
              {/* <button onClick={setClose}>Close Users</button> */}
            <FontAwesomeIcon className='pt-2 ps-2' onClick={setClose} icon={faXmarkCircle} 
              title='Users' style={{color:'blue' , height:25 , display:'inline'}}/>
            </Col>

            <Col className='col-3'>
            </Col>

            <Col>
              <h3>Users</h3>
            </Col>

          </Row></>
      }
      else{
        return <></>
      }
    }

    function getUsers( ) // Aqui solicitamos los usuarios
    {

        var query = "" ;

      //axios.post("http://localhost:3000/api/Data/getusers" , {
      axios.post( getGlobal() + "getusers" , {
          query
        } )
        .then(response => {
      
          console.log( "getusers " + response.data.length ) ;

          var items = response.data.map( (item) => {
            //return( <RightNewaItem  key={item.id} message={item.message.substring(0,25) + "..."}></RightNewaItem> ) 

            var arr = {

                first_name : item.first_name ,
                last_name : item.last_name ,
                code : item.code ,
                email : item.email ,
                created_at : item.created_at.replace( ".000Z" , "" ).replace( "T" , " ") ,
                status : item.status ,
                points : "0 pts" ,
                credits : "0"

            } ;


            return arr ;

            

          })

          console.log( "Data " + items.length) ;

          setData( items ) ; 
      
        })
        .catch(e => {
          console.log( "messages") ;
          console.log( e ) ;
        })
        .finally(  () => {
      
      
      })

    }

    useEffect(() =>{

        getUsers() ;



    } ,[])

    const columns = useMemo(
        () => [
            {
            accessorKey: 'first_name', //access nested data with dot notation
            header: 'First Name',
            size: 150,
            },
            {
            accessorKey: 'last_name',
            header: 'Last Name',
            size: 150,
            },
            {
            accessorKey: 'code', //normal accessorKey
            header: 'Code',
            size: 200,
            },
            {
            accessorKey: 'email',
            header: 'Email',
            size: 150,
            },
            {
            accessorKey: 'points',
            header: 'Points',
            size: 100,
            },
            {
            accessorKey: 'credits',
            header: 'Credits',
            size: 100,
            },
            {
            accessorKey: 'created_at',
            header: 'Date',
            size: 200,
            },
            {
            accessorKey: 'status',
            header: 'Status',
            size: 150,
            }
        
        
        ],
        [],
        );
    
  return (



      <div >

        {getRow()} ;


        <MaterialReactTable
        enableRowActions
        
        enableMultiRowSelection enableRowSelection enableColumnResizing columns={columns} data={data}   muiTableHeadCellProps={{
          //easier way to create media queries, no useMediaQuery hook needed.
          sx: {
            fontSize: {
              xs: '10px',
              sm: '11px',
              md: '12px',
              lg: '13px',
              xl: '14px',
            },
          },
        }} muiTableBodyCellProps={{  sx: {
          fontSize: {
            xs: '10px',
            sm: '11px',
            md: '12px',
            lg: '13px',
            xl: '14px',
          },
        },}} 

        renderRowActionMenuItems={({ table , row }) => [
          <MenuItem key="edit" onClick={() => table.setEditingRow(row)}>
            Edit
          </MenuItem>,
          <MenuItem key="add" onClick={() => table.setEditingRow(row)}>
            Add
          </MenuItem>,
          <MenuItem key="delete" onClick={() => { data.splice(row.index, 1); //assuming simple data table
  
          setData([...data]);}}>
            Delete
          </MenuItem>,
        ]}

        
        
        />

      </div>
  
  )
    
}
