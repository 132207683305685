import React , { useMemo , useEffect  , useState } from 'react'




import MaterialReactTable from 'material-react-table';

import axios from 'axios';

import { MenuItem } from '@mui/material';

import { Row , Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCircleUser, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'



function getFormatDate(date) {

    var dateStr =
        date.getFullYear() + "-" +
        ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
        ("00" + date.getDate()).slice(-2) + " " +
        ("00" + date.getHours()).slice(-2) + ":" +
        ("00" + date.getMinutes()).slice(-2) + ":" +
        ("00" + date.getSeconds()).slice(-2);
    console.log(dateStr);

    return dateStr;
}




export default function CompaniesTable( {showHeader = false , closeTable }) {

    const [data,setData] = useState([]) ;


    function getRow()
    {
        if ( showHeader )
        {

          return <>
            <Row>
              <Col className='col-2'>
                
                <FontAwesomeIcon className='pt-2 ps-2' onClick={setClose} icon={faXmarkCircle} 
                title='Close Companies' 
                style={{color:'blue' , height:25 , display:'inline'}}/>

              </Col>

              <Col className='col-3'>
              </Col>

              <Col>
                <h3>Companies</h3>
              </Col>

            </Row>
          </>

        }


    }

    function setClose()
    {
      closeTable() ;

    }

    function getUsers() // Aqui solicitamos los usuarios
    {

        var query = "" ;

      axios.post("http://localhost:3000/api/Data/getcompanies" , {
          query
        } )
        .then(response => {
      
          console.log( "getcompanies " + response.data.length ) ;

          var items = response.data.map( (item) => {
            //return( <RightNewaItem  key={item.id} message={item.message.substring(0,25) + "..."}></RightNewaItem> ) 

            var arr = {

                name : item.name ,
                quantity : item.quantity ,
                category : item.category ,
                website : item.website ,
                created_at : item.created_at.replace( ".000Z" , "" ).replace( "T" , " ") ,

            } ;


            return arr ;

            

          })

          console.log( "Data *** " + items.length) ;

          setData( items ) ; 
      
        })
        .catch(e => {
          console.log( "messages") ;
          console.log( e ) ;
        })
        .finally(  () => {
      
      
      })

    }

    useEffect(() =>{

        getUsers() ;



    } ,[])

    const columns = useMemo(
        () => [
            {
            accessorKey: 'name', //access nested data with dot notation
            header: 'Company Name',
            size: 150,
            },
            {
            accessorKey: 'website',
            header: 'Web Site',
            size: 150,
            },
            {
            accessorKey: 'quantity', //normal accessorKey
            header: 'Quantity Employees',
            size: 150,
            },
            {
                accessorKey: 'category', //normal accessorKey
                header: 'Category',
                size: 150,
            },
            {
            accessorKey: 'created_at',
            header: 'Date of register',
            size: 150,
            },
        
        
        ],
        [],
        );
    
  return (
 
    <>
        {getRow()} ;

        <MaterialReactTable
          enableRowActions 
          enableColumnResizing columns={columns} data={data}   muiTableHeadCellProps={{
          //easier way to create media queries, no useMediaQuery hook needed.
          sx: {
            fontSize: {
              xs: '10px',
              sm: '11px',
              md: '12px',
              lg: '13px',
              xl: '14px',
            },
          },
        }} muiTableBodyCellProps={{  sx: {
          fontSize: {
            xs: '10px',
            sm: '11px',
            md: '12px',
            lg: '13px',
            xl: '14px',
          },
        },}} 
        
        renderRowActionMenuItems={({ table , row }) => [
          <MenuItem key="edit" onClick={() => table.setEditingRow(row)}>
            Edit
          </MenuItem>,
          <MenuItem key="add" onClick={() => table.setEditingRow(row)}>
            Add
          </MenuItem>,
          <MenuItem key="delete" onClick={() => { data.splice(row.index, 1); //assuming simple data table
  
          setData([...data]);}}>
            Delete
          </MenuItem>,
        ]}
        
        />
    </>
  )
    
}
