import React , {  useState } from 'react'


import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome'
import {  faCircleDollarToSlot } from '@fortawesome/free-solid-svg-icons'





export default function InvoicesMenu( {  centerTable} ) {

  const [theOpen,setTheOpen] = useState( false ) ;


  function setCenterTable(  )
  {
      centerTable( "InvoicesTable") ;
  }



  return (
    <div  >

      
        <div  onClick={setCenterTable}>
          <FontAwesomeIcon  icon={faCircleDollarToSlot} title='Invoices' style={{color:'white' , height:25}}/>
        </div>
        


    </div>
  ) }