import React  from 'react'

import Modal from 'react-modal'

import { Row , Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faUserGroup } from '@fortawesome/free-solid-svg-icons'


import UserTable from '../Left2Menu/UserTable'



export default function CompanyMenu( { showText = true , stop ,  centerTable } ) {




  function setCenterTable(  )
  {
      centerTable( "CompanyAdd") ;

      stop(true) ;
  }


  return (
    <div  >

      
        <div className=' drop-item' onClick={setCenterTable}>
          <FontAwesomeIcon  icon={faUserGroup} title='Company' style={{color:'white' , height:25 , display:'inline'}}/>
          { showText  &&    (  <p style={{display:'inline'}}>  &nbsp; &nbsp;Add Company</p> ) }
        </div>
        

   
    </div>
  )
}