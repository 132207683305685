import React from 'react'

import { Row , Col, Carousel } from 'react-bootstrap'

//import Carousel01 from './Carousel01'
import Carousel01 from './Carousel01'

//import NewProyectsUpRight from './NewProyectsUpRight'
import NewProyectsUpRight from './NewProyectsUpRight'


export default function NewProyects() {
  return (
    <div style={{height:'220px' , width:'100%'}}>
        <Row style={{height:'30px'}}>

            <Col className='col-3'>
                <p style={{fontFamily:'satoshi-bold'}}>New Proyects</p>
            </Col>

            <Col className='col-5'>
                
            </Col>

            <Col className='col-4'>

              <NewProyectsUpRight></NewProyectsUpRight>
                
            </Col>



        </Row>

        <Row className='pt-2' style={{height:'200px'}}>

            <Carousel01></Carousel01>

        </Row>

    </div>
  )
}
