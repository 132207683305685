import Address2 from "./Address2";

import IndustriesAreas from "./IndustriesAreas";


function Company(props) {



    return (
    
        <div className="pt-2">

            <div className="row">
                <div className="col-sm">
                    <div className="form-group"  style={{width: "24rem"  }} >
                        <label htmlFor="companyname">Company Name</label>
                        <input className="form-control" id="companyname" type="text" maxLength={80} />
                    </div>
                </div>

                <div className="col-sm">
                    <div className="form-group"  style={{width: "24rem"  }} >
                        <label htmlFor="comercialname">Comercial Name</label>
                        <input className="form-control" id="comercialname" type="text" maxLength={80} />
                    </div>
                </div>

            </div>


            <div className="row">

                <div className="col-sm">
                    <div className="form-group"  style={{width: "24rem"  }} >
                        <label htmlFor="description">Description</label>
                        <input className="form-control" id="description" type="text" maxLength={80} size= {80}/>
                    </div>
                </div>

                <div className="col-sm">
                    <div className="form-group"  style={{width: "24rem"  }} >
                        <label htmlFor="companyemail">Company email</label>
                        <input className="form-control" id="companyemail" type="text" maxLength={80} size= {80}/>
                    </div>
                </div>

            </div>


            <Address2/>
            {
                props.isIndustry ==="true" ? ( <div><label>Industries</label> <IndustriesAreas/> </div>): ""
            }
           


        </div>
    )

}

export default Company ;