import React , {  useState } from 'react'

import Modal from 'react-modal'

//import { Row , Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faBridge} from '@fortawesome/free-solid-svg-icons'


import UserTable from '../Left2Menu/UserTable'



export default function CandidatesMenu( { /* showText = false , stop , top , */ centerTable } ) {


  const [theOpen,setTheOpen] = useState( false ) ;

  function setOpen()
  {
    


   //stop(true) ;
    //top( "Categories") ;
  }

  function setClose()
  {
    
    setTheOpen(false) ;
    //stop(false)
  }


  function setCenterTable(  )
  {
      centerTable( "Categories") ;
  }


  return (
    <div  >

      
        <div className=' drop-item'  onClick={setCenterTable}  >
          <FontAwesomeIcon  icon={faBridge} title='Categories' style={{color:'white' , height:25 , display:'inline'}}/>
          {/* { showText  &&    (  <p style={{display:'inline'}}>  &nbsp; &nbsp;Add Candidate</p> ) } */}
        </div>
        


    </div>
  )
}