
import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';


const data = [

    [
        { name: 'Accepted', value: 400 },
        { name: 'Pending', value: 300 },
        { name: 'Reject', value: 300 },
      ],
      [
        { name: 'Accepted', value: 300 },
        { name: 'Pending', value: 300 },
        { name: 'Reject', value: 300 },
      ],
      [
        { name: 'Accepted', value: 200 },
        { name: 'Pending', value: 100 },
        { name: 'Reject', value: 100 },
      ]

]

  
  const COLORS = ['#7507FA', '#19A5F4', '#F500FF'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  
  export default class ChartAccountDetails extends PureComponent  {
    static demoUrl = 'https://codesandbox.io/s/pie-chart-with-customized-label-dlhhj';
  
    render() {
      return (
        <ResponsiveContainer width="120%" height="120%">
          <PieChart width={400} height={400}>
            <Pie
              data={data[this.props.index]}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      );
    }
  }
  