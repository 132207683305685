import React from 'react'


import { Row , Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'


export default function ProyectStatus() {
  return (
    <div className='ps-2 pt-4' style={{width:'100%' , fontFamily:'Satoshi-Regular'}}>

        <Row  >
            <p className='font-2' style={{fontFamily:'satoshi-bold' }}>Platform Status</p>
        </Row>

        <Row style={{height:'20px'}}> 
            <Col className='col-2'>
                <FontAwesomeIcon   icon={faCircle} color='green'  ></FontAwesomeIcon>
            </Col>
            <Col className='col-6'>
                <p className='body-3'>Open Vacancies</p>
            </Col>

            <Col className='col-4'>
                <p className='body-3'>10 </p>
            </Col>
      
            
        </Row>

        <hr></hr>

        <Row style={{height:'20px'}}>
            <Col className='col-2'>
                <FontAwesomeIcon icon={faCircle} color='orange'></FontAwesomeIcon>
            </Col>
            <Col className='col-6'>
                <p className='body-3'>Closed Vacancies</p>
            </Col>

            <Col className='col-4'>
                <p className='body-3'> 21 </p>
            </Col>
    
        </Row>

        <hr></hr>

        <Row style={{height:'20px'}}>
            <Col className='col-2'>
                <FontAwesomeIcon icon={faCircle} color='pink'></FontAwesomeIcon>
            </Col>
            <Col className='col-6'>
                <p className='body-3'>Invoiced</p>
            </Col>

            <Col className='col-4'>
                <p className='body-3'>05</p>
            </Col>
        </Row>

        <hr></hr>

        <Row style={{height:'30px'}}>
            <Col className='col-2'>
                <FontAwesomeIcon icon={faCircle} color='purple'></FontAwesomeIcon>
            </Col>
            <Col className='col-6'>
                <p className='body-3'>Pipeline</p>
            </Col>

            <Col className='col-4'>
                <p className='body-3'>30</p>
            </Col>
        </Row>

        <Row style={{height:'20px'}}>

            {/* <Col className='col-8' style={{fontFamily:'satoshi-bold'}}>
                <p className='body-3'>Efficiency</p>
            </Col>

            <Col className='col-4'>
                <p className='body-3' >80%</p>
            </Col> */}

        </Row>

        



    </div>
  )
}
