import React from 'react'


import Company from '../../../../../src/UserElements/Company'

// D:\hunterhire\nexjjs\huntelfull\hunterhire\src\UserElements

import { Row , Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {   faXmarkCircle } from '@fortawesome/free-solid-svg-icons'


export default function CompanyAdd({closeTable}) {

    function setClose()
    {
      closeTable() ;
    }

  return (
    <div className='container  justify-content-center backemployer-2'>


        <Row>
            <Col className='col-2'>
                <h4>Add Company</h4>
            </Col>
            <Col className='col-9'>
                
            </Col>
            <Col className='col-1'>
                <FontAwesomeIcon className='pt-2 ps-2' onClick={setClose} icon={faXmarkCircle} 
                title='Close' style={{color:'blue' , height:25 }}/>
            </Col>

        </Row>
            <Button>Submit</Button>
        <Company></Company>
    </div>
  )
}
