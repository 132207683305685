import React , { useMemo , useEffect  , useState } from 'react'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {   faXmarkCircle } from '@fortawesome/free-solid-svg-icons'

import MaterialReactTable from 'material-react-table';


import { Row , Col } from 'react-bootstrap'

import axios from 'axios';

import getGlobal from '../../../../setglobal';


// function getFormatDate(date) {

//     var dateStr =
//         date.getFullYear() + "-" +
//         ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
//         ("00" + date.getDate()).slice(-2) + " " +
//         ("00" + date.getHours()).slice(-2) + ":" +
//         ("00" + date.getMinutes()).slice(-2) + ":" +
//         ("00" + date.getSeconds()).slice(-2);
//     console.log(dateStr);

//     return dateStr;
// }




export default function MessagesTable({showHeader = false , closeTable }) {

    const [data,setData] = useState([]) ;


    function setClose()
    {
      closeTable() ;

    }



    function getRow( )
    {
      if ( showHeader )
      {
        return <>
          <Row>
            <Col className='col-2'>
              {/* <button onClick={setClose}>Close Users</button> */}
            <FontAwesomeIcon className='pt-2 ps-2' onClick={setClose} icon={faXmarkCircle} 
              title='Users' style={{color:'blue' , height:25 , display:'inline'}}/>
            </Col>

            <Col className='col-3'>
            </Col>

            <Col>
              <h3>Users</h3>
            </Col>

          </Row></>
      }
      else{
        return <></>
      }
    }


    function getUsers() // Aqui solicitamos los usuarios
    {

        var query = "" ;

      //axios.post("http://localhost:3000/api/Data/getmessagesall" , {
      axios.post( getGlobal() + "getmessagesall" , {
          query
        } )
        .then(response => {
      
          console.log( "getmessagesall " + response.data.length ) ;

          var items = response.data.map( (item) => {
            //return( <RightNewaItem  key={item.id} message={item.message.substring(0,25) + "..."}></RightNewaItem> ) 

            var arr = {

                sender : item.sender ,
                receiver : item.receiver ,
                subject : item.subject ,
                message : item.message ,
                created_at : item.created_at.replace( ".000Z" , "" ).replace( "T" , " ") ,
                name : item.name 

            } ;


            return arr ;

            

          })

          console.log( "Data *** " + items.length) ;

          setData( items ) ; 
      
        })
        .catch(e => {
          console.log( "messages") ;
          console.log( e ) ;
        })
        .finally(  () => {
      
      
      })

    }

    useEffect(() =>{

        getUsers() ;



    } ,[])

    const columns = useMemo(
        () => [
            {
            accessorKey: 'sender', //access nested data with dot notation
            header: 'User',
            size: 150,
            },
            {
            accessorKey: 'receiver',
            header: 'Destinatary',
            size: 150,
            },
            {
            accessorKey: 'subject', //normal accessorKey
            header: 'Subject',
            size: 150,
            },
            {
                accessorKey: 'name', //normal accessorKey
                header: 'Oportunities',
                size: 150,
            },
            {
            accessorKey: 'message', //normal accessorKey
            header: 'Message',
            size: 300,
            },
            {
            accessorKey: 'created_at',
            header: 'Date of register',
            size: 150,
            },
        
        
        ],
        [],
        );
    
  return (
 
      <>

        { getRow() } ;

        <MaterialReactTable enableColumnResizing columns={columns} data={data}   muiTableHeadCellProps={{
          //easier way to create media queries, no useMediaQuery hook needed.
          sx: {
            fontSize: {
              xs: '10px',
              sm: '11px',
              md: '12px',
              lg: '13px',
              xl: '14px',
            },
          },
        }} muiTableBodyCellProps={{  sx: {
          fontSize: {
            xs: '10px',
            sm: '11px',
            md: '12px',
            lg: '13px',
            xl: '14px',
          },
        },}} />

      </>
  
  )
    
}
