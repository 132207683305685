import React from 'react'

import TopRight from './TopRight'

import {  Row , Col ,Image} from 'react-bootstrap'
//import { BsEmojiSmile } from 'react-icons/bs'
import { FiAtSign } from 'react-icons/fi'
//FiAtSign

// BsEmojiSmile

export default function Top( {centerTable}) {



  return (
    <div className='container-fluid header ' 
        >

       <Row className=' align-content-center'>

            <Col className="col-1">
            </Col>

            <Col className='col-3 flex-column'>
                <div className=' pt-4 align-content-center' >
                    {/* <BsEmojiSmile   > </BsEmojiSmile> */} 
                    <Image src='/smile-icon.svg' alt=""></Image> <div style={{display:'inline'}} > Hi!</div> 
                    <FiAtSign></FiAtSign> <div style={{display:'inline'}} >adriana</div>
                </div>
            </Col>

            <Col className="col-2">
            </Col>

            <Col className='col-6 flex-column p-4'>
                <TopRight centerTable={centerTable}></TopRight>
            </Col>

       </Row>


    </div>
  )
}
